import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ImgSrcset from '../img-srcset';
import truncate from 'lodash/truncate';
import Button from '../button';
import Link from '../link';
import styles from './card.module.scss';

const Card = ({
  image,
  srcset,
  sizes,
  tag,
  parentTag,
  title,
  date,
  summary,
  url,
  type,
  className,
  cardSummaryOnly
}) => (
  <div className={classnames(styles.card, className)}>
    <div className={styles.cardImage}>
      {/* Default to image with srcset. Fall back to plain images. */}
      {srcset && (
        <ImgSrcset
          sizes={sizes}
          srcs={srcset}
          alt={image.alt}
          width="343"
          height="193"
        />
      )}
      {image && !srcset && (
        <img alt={image.alt} src={image.src} width="343" height="193" />
      )}
    </div>
    <div className={styles.cardContent}>
      <div className={styles.cardTag}>{parentTag ? parentTag : tag}</div>
      {/* If there is something to click through to, make the title a link. */}
      {!cardSummaryOnly && url && (
        <Link to={url} className={styles.cardLink}>
          <h2 className={styles.cardTitle}>{title}</h2>
        </Link>
      )}
      {/* If there isn't, just make it text. */}
      {cardSummaryOnly && <h2 className={styles.cardTitle}>{title}</h2>}
      {date && <div className={styles.cardDate}>{date}</div>}
      <div className={styles.cardSummary}>
        {summary?.length > 280 ? `${summary.substring(0, 280)}...` : summary}
      </div>
      {!cardSummaryOnly && (
        <Button
          aria-label={`Read more about ${title}`}
          className={styles.cardButton}
          to={url}
          borderStyle="round"
          color="blue"
          type="button"
        >
          {type === 'Place' && 'Learn More'}
          {type === 'News' && 'Read More'}
          {type === 'Event' && 'View Event'}
          {type === 'Page' && 'View Page'}
        </Button>
      )}
    </div>
  </div>
);
Card.propTypes = {
  title: PropTypes.string,
  link: PropTypes.object,
  image: PropTypes.object,
  srcset: PropTypes.array,
  tag: PropTypes.string,
  parentTag: PropTypes.string,
  type: PropTypes.string,
  summary: PropTypes.string,
  date: PropTypes.string,
  linkText: PropTypes.string,
  url: PropTypes.string,
  sizes: PropTypes.string,
  className: PropTypes.string,
  cardSummaryOnly: PropTypes.bool
};

export default Card;
