import React from 'react';
import PropTypes from 'prop-types';

/**
 * A component that takes an array of image sources, builds
 * a srcset attribute and returns a responsive image element.
 *
 * More info: https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/.
 *
 * @param {string} sizes The sizes attribute used with srcset.
 * @param {string} className The class names passed into the component.
 * @param {string} alt Alternative text for the image.
 * @param {string} width Image default width.
 * @param {string} height Image default height.
 * @param {array} srcs An array of absolute image urls.
 * @return {JSX} A <img> element with the srcset attribute.
 */
const ImgSrcset = ({ sizes, srcs, alt, className, width, height }) => {
  // Build an array of image src and widths.
  const processedSrcs = srcs
    .map((src) => {
      // The image width is contained in the source.
      // i.e. w344.
      // Pull it out of the url.
      const imageWidth = src.match(/w([0-9]+)/)?.[1] ?? '';
      return imageWidth === ''
        ? null
        : {
            width: parseInt(imageWidth, 10),
            src
          };
    })
    .filter(String);

  if (processedSrcs.length === 0) {
    return null;
  }

  // Sort the image widths in asc order.
  processedSrcs.sort((a, b) => a.width - b.width);

  // Grab the largest of the widths (which is the last item in the array).
  const largestSrc = processedSrcs[processedSrcs.length - 1];

  // Create a default just in case sizes aren't provided.
  const defaultSizes = `(max-width: ${largestSrc.width}px) 100vw, ${largestSrc.width}px`;

  // Create the srcSet string using our sorted images.
  let srcset = '';
  for (let i = 0; i < processedSrcs.length; i++) {
    srcset += `${processedSrcs[i].src} ${processedSrcs[i].width}w`;
    // Add a comma unless it's the last item in the array.
    if (i < processedSrcs.length) {
      srcset += ', ';
    }
  }

  return (
    <img
      src={largestSrc.src}
      sizes={sizes ? sizes : defaultSizes}
      srcSet={srcset}
      className={className}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

ImgSrcset.propTypes = {
  sizes: PropTypes.string,
  srcs: PropTypes.array.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};

export default ImgSrcset;
